import React from 'react';
import { useState } from 'react';
import { Container, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import { getMonthString } from '../../../utils/utils';

import { Arrow, Info } from '../../../resources/images/icons/';
import css from './Definitionbar.module.css';

const StyledButton = styled(Button)`
  &,
  &:hover,
  &:active,
  &:focus {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  & path {
    stroke: var(--background-accent);
    fill: transparent;
    transition: var(--animation-med) ease;
  }
  &:hover path {
    fill: var(--background-accent);
    transition: var(--animation-med) ease;
  }
`;

function Definitionbar(props) {
  const [expanded, setExpanded] = useState(
    props.expanded !== undefined ? props.expanded : false
  );

  const handleClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Container>
      <div className={css.definition + ' ' + (!expanded ? css.expanded : '')}>
        <label className={css.def}>{props.def}</label>
        <label className={css.function}>{props.function}</label>
        <div
          className={css.signVideo}
          style={{
            width: '100%',
            maxWidth: 'calc(var(--scale) * 12 + 8rem',
            margin: 'calc(var(--scale) * 1.25 + 3rem) auto 0 auto',
          }}
        >
          <VideoPlayer hidden={expanded} src={props.src} />
        </div>
        <StyledButton
          className={css.dropdown + ' ' + (expanded ? css.expanded : '')}
          onClick={handleClick}
        >
          <Arrow />
        </StyledButton>
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={`tooltip-${'top'}`}>
              "Definition of {props.word}". Oxford University Press. Lexico.com.{' '}
              {new Date().getDate()} {getMonthString(new Date())}{' '}
              {new Date().getFullYear()}. https://www.lexico.com/definition/
              {props.word}.
            </Tooltip>
          }
        >
          <div className={css.info + ' ' + (expanded ? css.expanded : '')}>
            <Info />
          </div>
        </OverlayTrigger>
      </div>
    </Container>
  );
}

export default Definitionbar;
