import React from 'react';
import SearchBar from '../../components/Searchbar/Searchbar';
import Titlebar from '../../components/containers/Titlebar/Titlebar';
import Definitionbar from '../../components/containers/Definitionbar/Definitionbar';
import { Book } from '../../resources/images/icons/';

function Dictionary(props) {
  return (
    <div>
      <SearchBar />
      {props.listDefinitions && (
        <Titlebar text={props.listDefinitions.word}>
          <Book />
        </Titlebar>
      )}
      {props.listDefinitions &&
        props.listDefinitions.definitions.map((element, index) => (
          <Definitionbar
            active={true}
            def={element.def}
            function={element.function}
            src={element.src}
          />
        ))}
    </div>
  );
}

export default Dictionary;
