import React from 'react';
import Navbar from './components/containers/Navbar/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'urql';
import {
  Home,
  About,
  Privacy,
  Terms,
  Flashcards,
  Dictionary,
  Word,
  Community,
  Account,
} from './pages';

import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import { getClient } from './services/graphClient';
import Footer from './components/containers/Footer/Footer';

function Wrapper(): JSX.Element {
  const client = getClient(true)
  return (
    <Provider value={client}>
      <App />
    </Provider>
  )
}

function App(): JSX.Element {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Navbar></Navbar>
        </header>
        <div className="App-body">
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/privacy">
              <Privacy />
            </Route>
            <Route path="/terms">
              <Terms />
            </Route>
            <Route path="/Flashcards">
              <Flashcards />
            </Route>
            <Route path="/Dictionary">
              <Dictionary />
            </Route>
            <Route path="/word" component={Word}>
              <Word />
            </Route>
            <Route path="/Community">
              <Community />
            </Route>
            <Route path="/Account">
              <Account />
            </Route>
          </Switch>
        </div>
        <footer className="App-footer"><Footer /></footer>
      </div>
    </Router>
  );
}

export default Wrapper;
