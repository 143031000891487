import { createContext, useContext, useState } from 'react';
const INITIAL_STATE: Count = 0;

type Count = number;
type SetCount = (count: Count) => void;
type Increment = () => void;
type Decrement = () => void;

interface CountContextValue {
  count: Count;
  setCount: SetCount;
}

const CountContext = createContext({
  count: INITIAL_STATE,
  setCount: () => null,
} as CountContextValue);

export function useCounter(): {
  count: Count;
  setCount: SetCount;
  increment: Increment;
  decrement: Decrement;
} {
  const { count, setCount } = useContext(CountContext);

  const increment: Increment = () => {
    setCount(count + 1);
  };
  const decrement: Decrement = () => {
    setCount(count - 1);
  };

  return { count, setCount, increment, decrement };
}

export function Provider(props): JSX.Element {
  const { children } = props;
  const [count, setCount] = useState(INITIAL_STATE);

  return (
    <CountContext.Provider value={{ count, setCount }}>
      {children}
    </CountContext.Provider>
  );
}
