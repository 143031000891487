import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/firestore';

var firebaseConfig = {
  apiKey: 'AIzaSyBIdNwlvxSb8xbUjpNLd0-t0NeCBmt8jtc',
  authDomain: 'dexterity-52828.firebaseapp.com',
  databaseURL: 'https://dexterity-52828-default-rtdb.firebaseio.com',
  projectId: 'dexterity-52828',
  storageBucket: 'dexterity-52828.appspot.com',
  messagingSenderId: '728976963342',
  appId: '1:728976963342:web:982c3cce60f4b9703bc95f',
  measurementId: 'G-VSQL7XWQ0L',
};

firebase.initializeApp(firebaseConfig);
// firebase.functions().useEmulator('localhost', 5000);

export default firebase;

// export const analytics = firebase.analytics();
// export const database = firebase.database();
// export const auth = firebase.auth();
export const storage = firebase.storage();
export const functions = firebase.functions();
export const db = firebase.firestore();
// export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
// export const messaging = firebase.messaging();
