import React from 'react';
import { useState, useRef } from 'react';
import { Button } from 'react-bootstrap';

import Video from '../../Video/Video';
import PlaybackSpeed from '../../PlaybackSpeed/PlaybackSpeed';

import css from './VideoPlayer.module.css';

function VideoPlayer(props) {
  const ref = useRef<Video>(null);

  const playIcon = <i className="fa fa-play"></i>;
  const pauseIcon = <i className="fa fa-pause"></i>;
  const undoIcon = <i className="fa fa-undo"></i>;

  const [buttonText, setButtonChild] = useState(playIcon);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [buttonDisabled, setbuttonDisabled] = useState(false);

  const updatePlayButton = () => {
    const isPlaying = videoPlaying;
    setButtonChild(!isPlaying ? pauseIcon : playIcon);
  };

  const toggleVideo = () => {
    const isPlaying = videoPlaying;
    if (null === ref) {
      throw Error('editor is null');
    }
    if (null === ref.current) {
      throw Error('editor.current is null');
    }
    if (isPlaying) {
      ref.current.pause();
    } else {
      ref.current.play();
    }
    setVideoPlaying(!isPlaying);
    updatePlayButton();
  };

  const setPlayBack = (speed) => {
    if (null === ref) {
      throw Error('editor is null');
    }
    if (null === ref.current) {
      throw Error('editor.current is null');
    }
    ref.current.setPlaybackRate(speed);
  };

  const endedVideoCallback = () => {
    setVideoPlaying(false);
    setButtonChild(undoIcon);
  };
  const loadedVideoCallback = () => {
    setbuttonDisabled(false);
  };

  return (
    <div className={props.hidden ? css.visible : css.hidden}>
      <Button
        disabled={buttonDisabled}
        onClick={toggleVideo}
        variant="primary"
        style={{
          width: 'calc(var(--scale) / 2 + 4rem)',
          padding: '.5rem 1.75rem',
          float: 'left',
        }}
      >
        {buttonText}
      </Button>
      <PlaybackSpeed
        disabled={buttonDisabled}
        float="right"
        speedCallback={setPlayBack}
      />
      <Video
        ref={ref}
        src={props.src}
        endedVideoCallback={endedVideoCallback}
        loadedVideoCallback={loadedVideoCallback}
      />
    </div>
  );
}

export default VideoPlayer;
