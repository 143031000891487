import React, { createRef } from 'react';
import { storage } from '../../services/firebase';

type Props = {
  src: string;
  endedVideoCallback: Function;
  loadedVideoCallback: Function;
};

interface IState {
  vidURL?: string;
}

class Video extends React.Component<Props, IState> {
  private vidRef;
  constructor(props) {
    super(props);
    this.vidRef = createRef<HTMLVideoElement>();

    this.state = {
      vidURL: 'undefined',
    };
  }
  focus() {
    if (this.vidRef.current) {
      this.vidRef.current.focus();
    }
  }

  pause() {
    this.vidRef.current.pause();
  }
  play() {
    this.vidRef.current.play();
  }
  setPlaybackRate(playbackRate) {
    this.vidRef.current.playbackRate = playbackRate;
  }

  componentDidMount() {
    storage
      .ref()
      .child(this.props.src)
      .getDownloadURL()
      .then((url) => {
        this.setState(() => {
          return { vidURL: url };
        });
        this.setState(
          {
            vidURL: url,
          },
          () => {
            this.vidRef.current.load();
          }
        );
      });
    this.vidRef.current.addEventListener(
      'ended',
      this.props.endedVideoCallback
    );
  }
  render() {
    const { vidURL } = this.state;
    return (
      <video
        muted
        style={{
          width: '100%',
          marginTop: '0.5rem',
          borderRadius: '0.25rem',
        }}
        ref={this.vidRef}
        onCanPlay={() => this.props.loadedVideoCallback()}
        src={vidURL}
      ></video>
    );
  }
}

export default Video;
