import { db, functions } from '../services/firebase';

const callFunction = async function () {
  const callableReturnMessage = functions.httpsCallable('helloCloud');

  callableReturnMessage()
    .then((result) => {
      console.log(result.data);
    })
    .catch((error) => {
      console.log(`error: ${JSON.stringify(error)}`);
    });
};

const getWord = async function (query, callback) {
  console.log('Making db request');
  var docRef = db.collection('words').doc(query);
  docRef
    .get()
    .then((doc) => {
      if (doc.exists && doc.data()) {
        let data = doc.data();
        if (data) {
          if ('definitions' in data) {
            callback([...data['definitions']]);
          }
          if ('def' in data) {
            callback([data]);
          }
        }
      } else {
        console.log('No such document!');
        callback([]);
      }
    })
    .catch((error) => {
      console.log('Error getting document:', error);
    });
};

export { callFunction, getWord };
