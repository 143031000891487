import { Container } from 'react-bootstrap';

function VoidWord() {
  return (
    <Container style={{ padding: '2rem 0rem' }}>
      <h1>Whoops!</h1>
      <h6>We don't have that word yet</h6>
      <p>
        We haven't added that word to the site yet, but the list of upcoming
        words can be found <a href="/pipeline">here</a>.
      </p>
    </Container>
  );
}

export default VoidWord;
