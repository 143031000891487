import { gql, useQuery } from 'urql';
import { Container, Jumbotron, Button } from 'react-bootstrap';

import styled from 'styled-components';

const JumbotronStyled = styled(Jumbotron)`
  background-color: var(--background-accent);
`;

const SampleQuery = gql`
  query {
    hello
  }
`

function Home(props) {
  const [{ data, fetching, error }, query] = useQuery({
    query: SampleQuery
  })

  if (fetching) return <p>Loading...</p>;
  if (error) return <p>Oh no... {JSON.stringify(error)}</p>;

  return (
    <Container style={{ padding: '1rem 0rem' }}>
      <JumbotronStyled>
        <h1>Welcome to Dexterity!</h1>
        <p>
          Practice your sign language vocabulary with study cards, create your
          own or pick from the most popular stacks from other users.
        </p>
        <p>
          <Button variant="success">Get Started</Button>
        </p>
      </JumbotronStyled>
      <Button
        onClick={() => {
          query();
        }}
      >
        callCloud
      </Button>
      <h1>{JSON.stringify(data)}</h1>
    </Container>
  );
}

export default Home;
