import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

import Link from '../../Link';
import IconBubble from '../../IconBubble';

import insta from '../../../resources/IconInstagram.png';
import twitter from '../../../resources/IconTwitter.png';
import linkedin from '../../../resources/IconLinkedin-in.png';

import css from './Footer.module.css';

const Footer = () => {
  const isMobile = useMediaQuery({
    query: '(max-width: 1199px)',
  });

  return (
    <Container fluid className={css.dark}>
      <Container style={{ padding: '1rem 0rem' }}>
        <Row>
          <Col>
            <h5>Legal</h5>
            <div style={{ padding: '0.5rem 1rem' }}>
              <Link muted to="/privacy">
                Privacy Policy
              </Link>
            </div>
            <div style={{ padding: '0.5rem 1rem' }}>
              <Link muted to="/terms">
                Terms and Conditions
              </Link>
            </div>
            <h6 style={{ padding: '0.5rem 1rem' }}>© 2021 Mitchell Hoppe</h6>
          </Col>
          <Col
            className="justify-content-between"
            xs={6}
            style={{ border: 'solid 1px rgba(255, 255, 255, 0.25)' }}
          >
            <h5>Social</h5>
            <Row className="justify-content-around">
              <IconBubble
                href="https://www.instagram.com/mitchell_hoppe"
                src={insta}
              />
              <IconBubble href="https://mitchellhoppe.dev" src={twitter} />
              <IconBubble
                href="https://www.linkedin.com/in/mitchell-hoppe-b78078163/"
                src={linkedin}
              />
            </Row>
            <Row className="justify-content-center">
              <h6 style={{ padding: '0.5rem 1rem' }}>
                Designed and Developed by
                <Link
                  to={{ pathname: 'https://mitchellhoppe.dev' }}
                  target="_blank"
                >
                  Mitchell Hoppe
                </Link>
              </h6>
            </Row>
            <Row className="justify-content-center">
              <h6 style={{ padding: '0.5rem 1rem' }}>
                Support this site <Link to="/support">Dexterity</Link>
              </h6>
            </Row>
          </Col>
          <Col>
            <h5>Site</h5>
            <div style={{ padding: '0.5rem 1rem' }}>
              <Link muted to="/about">
                About
              </Link>
            </div>
            <div style={{ padding: '0.5rem 1rem' }}>
              <Link muted to="/bugs">
                Bug Report
              </Link>
            </div>
            <div style={{ padding: '0.5rem 1rem' }}>
              <Link muted to="/contact">
                Contact
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
