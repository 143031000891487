import React, { Fragment, useState } from 'react';
import { Container, InputGroup, FormControl } from 'react-bootstrap';
import { Typeahead, withAsync } from 'react-bootstrap-typeahead';
import styled from 'styled-components';

import { Search } from '../../resources/images/icons/';

const StyledTypeahead = styled(Typeahead)`
  & input {
    height: calc(var(--scale) * 2);
    color: var(--text-color-light);
    font-size: calc(var(--scale) / 1);
    white-space: nowrap;
    padding-left: calc(var(--scale) / 2);
    border-color: var(--background-accent);
    border-radius: calc(var(--scale) / 4);
    background: transparent;
    transition: var(--animation-med) ease;
  }
  & input::placeholder {
    color: var(--background-accent);
  }
  & input:focus {
    color: var(--text-color-light);
    background: transparent;
  }
  .rbt-aux {
    height: 0;
  }
`;
const StyledPrepend = styled(InputGroup.Prepend)`
  & {
    height: calc(var(--scale) * 2);
    width: calc(var(--scale) * 2);
    transition: var(--animation-med) ease;
  }

  & span {
    width: 100%;
    height: 100%;
    padding: calc(var(--scale) / 4) calc(var(--scale) / 6);
    font-size: calc(var(--scale) / 1);
    border-color: var(--background-accent);
    border-radius: calc(var(--scale) / 4);
    justify-content: center;
    background: transparent;
    transition: var(--animation-med) ease;
  }
  & svg {
    width: 80%;
    height: 80%;
  }
  & span path {
    stroke-width: 1.2;
    stroke: var(--background-accent);
  }
`;
const AsyncTypeahead = withAsync(StyledTypeahead);

function Searchbar() {
  const SEARCH_URI = 'https://api.github.com/search/users';

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  // TODO: implement searching for sign words and Stacks

  const handleSearch = (query) => {
    setIsLoading(true);

    fetch(`${SEARCH_URI}?q=${query}+in:login&page=1&per_page=50`)
      .then((resp) => resp.json())
      .then(({ items }) => {
        var options = [];
        if (items !== undefined) {
          options = items.map((i) => ({
            avatar_url: i.avatar_url,
            id: i.id,
            login: i.login,
          }));
        }

        setOptions(options);
        setIsLoading(false);
      });
  };
  //     var options = [];
  //     if (items !== undefined) {
  //       options = items.map((i) => ({
  //         name: i.name,
  //         def: i.def,
  //         function: i.function,
  //         src: i.src,
  //       }));
  //     }

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  return (
    <Container>
      <AsyncTypeahead
        filterBy={filterBy}
        id="async-example"
        isLoading={isLoading}
        labelKey="login"
        minLength={3}
        onSearch={handleSearch}
        options={options}
        placeholder="Search words..."
        renderMenuItemChildren={(option, props) => (
          <Fragment>
            <img
              alt={option.login}
              src={option.avatar_url}
              style={{
                height: '24px',
                marginRight: '10px',
                width: '24px',
              }}
            />
            <span>{option.login}</span>
          </Fragment>
        )}
        renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
          <InputGroup className="mb-3">
            <StyledPrepend>
              <InputGroup.Text id="basic-addon1">
                <Search />
              </InputGroup.Text>
            </StyledPrepend>
            <FormControl
              aria-describedby="basic-addon1"
              {...inputProps}
              ref={(input) => {
                inputRef(input);
                referenceElementRef(input);
              }}
            />
          </InputGroup>
        )}
      />
    </Container>
  );
}

export default Searchbar;
