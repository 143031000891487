import React from 'react';

import css from './IconBubble.module.css';

function IconBubble(props) {
  return (
    <a href={props.href} className={css.link}>
      <img src={props.src} alt="" />
    </a>
  );
}

export default IconBubble;
