import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import Link from '../../Link';
import NavLink from '../Navlink/NavLink';

import css from '../Footer/Footer.module.css';

import {
  Flashcards,
  Book,
  People,
  Account,
} from '../../../resources/images/icons/';
import { ReactComponent as DexIcon } from '../../../resources/images/logo/dexterity-logo-crop.svg';

const Navbar = (props) => {
  const location = useLocation();

  const isMobile = useMediaQuery({
    query: '(max-width: 1024px)',
  });

  const fixed = isMobile ? 'fixed-bottom' : '';
  const ContainerClasses = `${fixed} ${css.dark}`;

  const align = isMobile ? 'align-items-center' : 'align-items-end';
  const RowClasses = `justify-content-around ${align}`;

  return (
    <Container fluid className={ContainerClasses}>
      <Container style={{ padding: isMobile ? '0.5rem 0' : '1rem 0' }}>
        <Row className={RowClasses}>
          <Link to="/flashcards" underline="false">
            <NavLink
              active={location.pathname === '/flashcards'}
              name={isMobile ? undefined : 'Flashcards'}
            >
              <Flashcards />
            </NavLink>
          </Link>
          <Link to="/dictionary" underline="false">
            <NavLink
              active={location.pathname === '/dictionary'}
              name={isMobile ? undefined : 'Dictionary'}
            >
              <Book />
            </NavLink>
          </Link>
          <Link to="/" underline="false">
            <NavLink active={false} brand={true} name={isMobile ? undefined : 'Home'}>
              <DexIcon />
            </NavLink>
          </Link>
          <Link to="/community" underline="false">
            <NavLink
              active={location.pathname === '/community'}
              name={isMobile ? undefined : 'Community'}
            >
              <People />
            </NavLink>
          </Link>
          <Link to="/account" underline="false">
            <NavLink
              active={location.pathname === '/account'}
              name={isMobile ? undefined : 'Account'}
            >
              <Account />
            </NavLink>
          </Link>
        </Row>
      </Container>
    </Container>
  );
};

export default Navbar;
