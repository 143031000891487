import React from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';

const data = {
  speeds: [
    { name: '0.25x', speed: 0.25 },
    { name: '0.50x', speed: 0.5 },
    { name: '1.00x', speed: 1.0 },
    { name: '2.00x', speed: 2 },
  ],
};

function PlaybackSpeed(props) {
  const [index, setIndex] = useState(2);

  const buttonPress = () => {
    setIndex((index + 1) % data.speeds.length);
    var newSpeed = data.speeds[(index + 1) % data.speeds.length].speed;
    props.speedCallback(newSpeed);
  };

  return (
    <Button
      disabled={props.disabled}
      variant="primary"
      style={{
        width: 'calc(var(--scale) / 2 + 4rem)',
        padding: '.5rem .75rem',
        float: props.float,
      }}
      onClick={buttonPress}
    >
      {data.speeds[index].name}
    </Button>
  );
}

export default PlaybackSpeed;
