import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(RouterLink)`
  & {
    color: ${(props) =>
      props.muted === undefined || props.muted === 'true'
        ? 'rgba(255, 255, 255, 1)'
        : 'rgba(255, 255, 255, 0.5)'};
    white-space: nowrap;
  }
  &:hover {
    color: rgba(255, 255, 255, 0.75);
    text-decoration: ${(props) =>
      props.underline === undefined || props.underline === 'true'
        ? 'underline'
        : 'none'};
  }
`;
function Link(props) {
  return (
    <StyledLink
      muted={props.muted}
      to={props.to}
      target={props.target}
      underline={props.underline}
    >
      {props.children}
    </StyledLink>
  );
}

export default Link;
