import React from 'react';
import { Container, InputGroup, FormLabel } from 'react-bootstrap';
import styled from 'styled-components';

const StyledInputGroup = styled(InputGroup)``;
const StyledFormLabel = styled(FormLabel)`
  & {
    width: calc(100% - (var(--scale) * 2));
    height: calc(var(--scale) * 2);
    line-height: calc(var(--scale) * 2);
    color: var(--background-accent);
    font-size: calc(var(--scale) / 1);
    white-space: nowrap;
    padding-left: calc(var(--scale) / 2);
    margin: 0;
    border-color: var(--background-accent);
    border: 1px solid;
    border-radius: 0 calc(var(--scale) / 4) calc(var(--scale) / 4) 0;
    background: transparent;
    transition: var(--animation-med) ease;
  }
  &::placeholder {
    color: var(--background-accent);
  }
  &:focus {
    color: var(--background-accent);
    background: transparent;
  }
`;
const StyledPrepend = styled(InputGroup.Prepend)`
  & {
    height: calc(var(--scale) * 2);
    width: calc(var(--scale) * 2);
    transition: var(--animation-med) ease;
  }

  & span {
    width: 100%;
    height: 100%;
    padding: calc(var(--scale) / 4) calc(var(--scale) / 6);
    font-size: calc(var(--scale) / 1);
    border-color: var(--background-accent);
    border-radius: calc(var(--scale) / 4);
    justify-content: center;
    background: transparent;
    transition: var(--animation-med) ease;
  }
  & svg {
    width: 80%;
    height: 80%;
  }
  & span path {
    stroke-width: 1.2;
    stroke: var(--background-accent);
  }
`;

function Titlebar(props) {
  return (
    <Container>
      <StyledInputGroup className="mb-3">
        <StyledPrepend>
          <InputGroup.Text id="basic-addon1">{props.children}</InputGroup.Text>
        </StyledPrepend>
        <StyledFormLabel aria-describedby="basic-addon1">
          {props.text}
        </StyledFormLabel>
      </StyledInputGroup>
    </Container>
  );
}

export default Titlebar;
