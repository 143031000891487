import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Titlebar from '../../components/containers/Titlebar/Titlebar';
import Definitionbar from '../../components/containers/Definitionbar/Definitionbar';
import VoidWord from './VoidWord';
import { getWord } from '../../services/api';
import { Book } from '../../resources/images/icons/';

function Word(props) {
  const location = useLocation();
  const pathArray = location.pathname.split('/');
  const word = pathArray[pathArray.length - 1];

  useEffect(() => {
    getWord(word, addDefinition);
  }, [word]);

  const [listDefinitions, pushDefinition] = useState<object[]>([]);
  const [pulledAPI, changePulledAPI] = useState<boolean>(false);

  const addDefinition = (newDefinitions) => {
    changePulledAPI(true);
    pushDefinition(() => [...newDefinitions]);
  };

  return (
    <div>
      {!!listDefinitions.length && word !== undefined && (
        <Titlebar text={word}>
          <Book />
        </Titlebar>
      )}
      {!!listDefinitions.length &&
        listDefinitions.map((element, index) => (
          <Definitionbar
            expanded={index === 0}
            active={true}
            key={element['def']}
            word={word}
            def={element['def']}
            function={element['function']}
            src={element['src']}
          />
        ))}
      {!listDefinitions.length && pulledAPI && <VoidWord />}
    </div>
  );
}

export default Word;
