import { Client, cacheExchange, fetchExchange } from 'urql';


export function getClient(dev: boolean) {
  const url = dev 
    ? 'http://localhost:7071/api/graphql' 
    : 'https://apollo-example-dexterity.azurewebsites.net/api/graphql?code=ez8ljf_dnWZZjJBYOzwsm9RZ2ZtdiKPCTunFKrDAr5v8AzFulRbBKQ=='
  

  const client = new Client({
    url,
    exchanges: [cacheExchange, fetchExchange],
  });

  return client
}