import React from 'react';

import css from './NavLink.module.css';

const NavLink = (props) => {
  const active = props.active ? css.active : css.inactive;
  const brand = props.brand ? css.brand : css.link;
  const FigureClasses = `${active} ${brand} ${css.figure}`;

  return (
    <figure className={FigureClasses}>
      {props.children}
      {props.name && (
        <figcaption className={css.caption}>{props.name}</figcaption>
      )}
    </figure>
  );
};

export default NavLink;
